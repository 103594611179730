'use client';

import { useEffect } from 'react';

function getErrorText() {
  const errorStage = localStorage.getItem('errorStage');
  if (errorStage === '1') {
    return 'Something is wrong, returning to the home page.';
  }
  if (errorStage === '2') {
    return 'There is a problem with the site, please try again later.';
  }
  return "Let's try that again...";
}

function clearStaleErrorState() {
  if (!localStorage.getItem('errorStage')) {
    return;
  }
  const errorTime = localStorage.getItem('errorTime');
  if (!errorTime || Number(errorTime) < new Date().getTime() - 60_000) {
    localStorage.removeItem('errorStage');
    localStorage.removeItem('errorTime');
  }
}

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  console.error('Error boundary', error);
  clearStaleErrorState();
  useEffect(() => {
    const errorStage = localStorage.getItem('errorStage');
    setTimeout(() => {
      if (!errorStage) {
        localStorage.setItem('errorStage', '1');
        localStorage.setItem('errorTime', new Date().getTime().toString());
        location.reload();
      } else if (errorStage === '1') {
        localStorage.setItem('errorStage', '2');
        location.replace('/');
      } else if (errorStage === '2') {
        localStorage.removeItem('errorStage');
        localStorage.removeItem('errorTime');
      }
    }, 2000);
  }, []);

  return (
    <div>
      <h2 className="text-center mt-8 px-4 text-lg md:text-xl">
        {getErrorText()}
      </h2>
    </div>
  );
}
